@import "../../node_modules/mapbox-gl-controls/lib/controls.css";
@import "../../node_modules/mapbox-gl/dist/mapbox-gl.css";
@import "../../node_modules/font-awesome/css/font-awesome.min.css";

:root {
    --primary-color: rgb(71,
            168,
            87);
}

@page {
    size: A4;
    background-color: white;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.text {
    @apply text-xl font-bold
}

.input {
    @apply rounded bg-gray-300 pt-2 py-2 text-center
}

#print:hover span {
    display: inline;
}

#print {
    box-shadow: 2 5px 20px rgba(59, 171, 54, .84);
}

#header,
#print,
#map {
    background-color: var(--primary-color);
}

#cible {
    width: 40%;
    top: 33%;
    left: 31%;
}


.mapboxgl-ctrl-bottom-right {
    display: none;
}

.mapboxgl-canvas {
    border-radius: 0.75rem;
}

svg {
    display: inline;
}


.selected_button i,
.selected_button svg {
    filter: brightness(0) saturate(100%) invert(68%) sepia(10%) saturate(5485%) hue-rotate(163deg) brightness(96%) contrast(86%);
}

canvas#chart{
    z-index: 1;
}

@media print {

    body {
        width: 21cm;
        height: 29.7cm;
    }

    #pdf_page {
        -webkit-print-color-adjust: exact;
        width: 21cm;
        height: 29.7cm;
    }

    .text {
        @apply text-xl font-bold
    }

    .input {
        @apply rounded bg-gray-300 pt-2 py-2 text-center
    }

    .mapboxgl-control-container {
        display: none;
    }

    .mapboxgl-canvas {
        border-radius: 0.75rem;
    }

    .no_print {
        display: none;
    }
}