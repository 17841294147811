.mapbox-control {
  button {
    display: flex;
    align-items: center;
    justify-content: center;

    &.-active {
      svg {
        fill: #4264fb;
      }
    }
  }
}