.mapbox-control-image {
  button {
    overflow: hidden;
  }

  input[type="file"] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}