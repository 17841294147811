.mapbox-control-inspect {
  &-popup {
    position: absolute;
    padding-top: 5px;
    transform: translate(-50%, 0);
  }

  &-content {
    position: relative;
    padding: 4px 8px;
    font-size: 12px;
    font-family: sans-serif;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
    white-space: nowrap;

    &:before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    user-select: none;
  }

  &-prev,
  &-next {
    padding: 2px 12px;
    border-radius: 4px;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
      cursor: pointer;

      svg {
        fill: #4264fb;
      }
    }

    svg {
      vertical-align: top;
    }
  }

  &-prev {
    margin-right: 6px;
  }

  &-next {
    margin-left: 6px;
  }

  &-grid {
    width: 100%;
    min-width: 200px;
    max-width: 400px;
    border-collapse: collapse;

    tr:not(:last-child) {
      td, th {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }
    }
  }

  &-key,
  &-value {
    width: 50%;
    padding: 5px;
    white-space: break-spaces;
  }

  &-key {
    text-align: right;
  }

  &-value {
    text-align: left;
  }

  &-caption {
    text-align: center;
    color: #4264fb;
  }
}
