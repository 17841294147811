.mapbox-control-styles {
  display: flex;
  overflow: hidden;

  button {
    width: auto;
    display: flex;
    align-items: center;
    padding: 0 8px;

    & + button {
      border: none;
    }

    &.-active {
      background: #f5f5f5;
    }
  }
}
